<template>
  <v-carousel v-model="model"
  hide-delimiter-background
  delimiter-icon="mdi-circle"
  :show-arrows="false"
  height="35rem"
  cycle
  >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="$vuetify.breakpoint.mdAndUp ? item.src : item.src_min"

    > 
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="white darken-3"
          ></v-progress-circular>
        </v-row>
      </template> 
      
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
    name: "Carousel",
    data: () => ({        
      model: 0,
      items: [
          {
            src: require('@/assets/slider/viadukt.jpg'),
            src_min: require('@/assets/slider/viadukt.jpg'),
          }
        ],     
    }),
  }
</script>


<style scoped>
  #text{
    background-color:rgba(0,0,0,0.6);
  }
</style>

  
