<template>
    <v-container fluid v-if="$vuetify.breakpoint.mdAndUp">      
        <v-row class="ml-15" justify="center">
          <v-col cols="10">
              <div id="title" align="center" class="mt-10" stlye="font-size:100px">Programok</div>
              <v-divider class="mb-5"/>
          </v-col>
        </v-row>
        <v-row class="ml-15">
          <v-col cols="10" md="5">            
                <TimeLine                  
                  @clicked="setPostIdForContent"
                />                
            </v-col>
            <v-col cols="6">
              <v-slide-x-transition>
                <div v-if="show" id="card">
                      <SliderLayout 
                        v-if="showedProgram.typeId == 1"
                        :postId="showedPostId"
                      />
                      <NewspaperLayout
                        v-if="showedProgram.typeId == 2"
                        :postId="showedPostId"
                      />
                      <OneImageLayout
                        v-if="showedProgram.typeId == 3"
                        :postId="showedPostId"
                      />
                </div>
              </v-slide-x-transition>
            </v-col>
        </v-row>
        <v-row class="ml-15">
          <v-col >
            
          </v-col>
        </v-row>
    </v-container>

    <v-container v-else>
      <v-btn
        fab
        fixed
        right
        bottom
        color="primary"
        @click="$vuetify.goTo(1, {duration:500, easing:'easeInQuad'})"
      >
      <v-icon>mdi-chevron-up</v-icon>
      </v-btn>  
        <v-row class="" justify="center">
          <v-col cols="10">
              <div id="title" class="mt-10" align="center">Programok</div>
              <v-divider class="mb-5"/>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12" md="5">
                <TimeLine />
          </v-col>         
        </v-row>
    </v-container>
</template>

<script>
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
import TimeLine from '@/components/common/TimeLine'
import SliderLayout from '@/components/layouts/SliderLayout'
import NewspaperLayout from '@/components/layouts/NewspaperLayout'
import OneImageLayout from '@/components/layouts/OneImageLayout'
export default {
    components: {
        TimeLine,
        SliderLayout,
        NewspaperLayout,
        OneImageLayout
    },
    data(){
      return {
        showedPostId: 1,
        show:false
      }
    },
    methods: {
        setPostIdForContent(id){
          this.showedPostId = id
          if(this.show) {
             this.show = false
          }
          setTimeout(()=>{
            this.show = true
          },200)
        }
    },
    computed: {
      showedProgram: function(){
        return this.$store.getters.getActualPrograms.find(program => program.id == this.showedPostId)
      }
    }
}
</script>
<style scoped>
  @font-face {
    font-family: UniSans;
    src: url("~@/assets/fonts/Uni Sans Thin.otf");
  }
  #title{
    font-size:4rem;
    font-family: UniSans;
  }
  #card{
    margin: 12px 0 0 0;
  }
</style>