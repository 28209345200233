<template>
    <v-container>
        <v-btn href="/assets/documents/beszamolo_2017.pdf" target="_blank">
        Pdf
        </v-btn>
    </v-container>
</template>

<script>
export default {
    name:"AllPrograms",
    
}
</script>