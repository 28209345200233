<template>
    <v-container fluid>
        <v-card elevation="2">
            <div class="py-2" align="center">  
                {{ showedProgram.date }}      
                <h1>{{ showedProgram.title }}</h1>
                <v-divider class="mx-15 mt-2"></v-divider>     
            </div>
            <v-card-text class="black--text">
                {{showedProgram.text}}
            </v-card-text>
            <v-carousel cycle hide-delimiter-background>
                    <v-carousel-item v-for= "image in images" :key="image">
                        <v-img :src="image"/>
                    </v-carousel-item>
                </v-carousel>    
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "SliderLayout",


    props: {
        postId: {
            type: Number
        }
    },

    computed: {
        showedProgram: function(){
            this.$store.dispatch('fetchProgramById',this.postId)
            return this.$store.getters.getShowedProgram
        },
        images: function(){
            this.$store.dispatch('fetchProgramById',this.postId)
            let _showedProgram= this.$store.getters.getShowedProgram
            let loadedImages=[..._showedProgram.images]
            for (let i = 0; i < loadedImages.length; i++) {
                loadedImages[i]=require('@/assets/'+loadedImages[i])         
            }
            return loadedImages
        }
    } 

}
</script>
