<template>
  <v-col :md=_width sm=12>
        <v-card
          elevation=2
          class="ma-0"
        >
        <v-row no-gutters>
          
          <v-img
            :src="imgsrc"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-text>
            <div>{{title}}</div>
            <p class="text-h5 text--primary">
              {{subtitle}}
            </p>
            <v-divider class="mb-3"></v-divider>
            <div class="text--primary">
              {{text}}
            </div>
          </v-card-text>
          <v-card-actions>
              <v-btn
                  v-if="button"    
                  elevation=5
                  :color="button.color"
                  class="pa-5 ma-2"
                  :href="href"
                  target="_blank"
            >
              {{button.text}}
            </v-btn>      
          </v-card-actions>
        </v-row>
        </v-card>
  </v-col>
</template>

<script>
export default {
    name: "Card",
    props: {
        title: {
            type: String,
            default: "Cím"
        },
        subtitle: {
            type: String,
            default: "Alcím"
        },
        text: {
            type: String,
            default: "Szöveg"
        },

         button: {
            type: Object,
            default: null
        },
        href: {
            type: String,
            default: ""
        },
        imgsrc: {
            type: String,
            default: ""
        },
        _width : {
          type: Number,
          default: "6"
        }
    }
}
</script>

<style>

</style>