<template>
  <v-app>
    <Navbar/>
    <v-main v-scroll="onScroll">
      <router-view/>
<!--      <FloatingButton id="floatingButton" :offset="offsetTop"/>-->
    </v-main>
    <Footer/>
    <div class="eu_info">
      <img alt="eu_info" src="@/assets/images/eu_info.png" width="300">
    </div>
  </v-app>
</template>

<script>
  import FloatingButton from '@/components/common/FloatingButton' 
  import Navbar from '@/components/common/Navbar'
  import Footer from '@/components/common/Footer'
export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    FloatingButton
  },
  data: () => ({
        offsetTop: 0
    }),
    methods: {
      onScroll (e) {
        this.offsetTop = e.target.documentElement.scrollTop
      },
    }
};
</script>
<style>
.eu_info {
  position: fixed;
  bottom:0;
  right:0;
  margin-bottom: -7px;
  opacity: 0.7;
  transition: 500ms;
}
.eu_info:hover {
  opacity: 1;
}
</style>