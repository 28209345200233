<template>
  <v-container fluid>
    <v-row
      justify="center"
    > 
    <Carousel class="ma-5"/>
    <div class="mx-6" align="center">Az Egészséges Biatorbágyért Közösségi Egyesület 14 alapító taggal a településen dolgozó orvosok és civil tagok támogatásával alakult 2001-ben.<br>
    Célunk az volt, hogy az egészség megőrzése fontos és hangsúlyos szerepet kapjon a lakosság körében.</div>
    </v-row>
    <v-row class="mt-6" justify="center" align="center">
    <v-btn
      color="primary"
       to="/rolunk"
    >
       Rólunk bővebben!     
    </v-btn>
    </v-row>
  </v-container>
</template>

<script>

  import Card from "@/components/common/Card"
  import Carousel from "@/components/common/Carousel"

  export default {    
    name: 'Home',
    components: {
      Card,
      Carousel
    },
  }
</script>
