<template>
  <v-btn
        fab
        fixed
        right
        bottom
        color="primary"
         v-show="offset > 0"
        @click="$vuetify.goTo(0, {duration:500, easing:'easeInQuad'})"       
      >
      <v-icon>mdi-chevron-up</v-icon>
      </v-btn> 
</template>

<script>
export default {
    name: "FloatingButton",
    props: {
        offset: {
            type: Number,
            default: 0
        }
    }
}
</script>