<template>
<v-container fluid>
    <v-card elevation="2">
        <div class="pt-2 pl-2">{{ showedProgram.date }}</div>
        <div align="center">
            <v-divider class="ma-2 black"/>       
            <h1>{{ showedProgram.title }}</h1>
            <v-divider class="ma-2 black"/>
        </div>
        <v-row no-gutters>
            <v-col>                   
                <v-card-text>
                    {{showedProgram.text}} 
                </v-card-text>
            </v-col>
            <v-col class="mr-3 mt-3" cols="12" lg="5">
                <v-img aspect-ratio="1" width="20vw" src="@/assets/slider/1.jpg"></v-img>
            </v-col>
        </v-row>
        <v-divider class="my-3 mx-2 black"/>
        <v-row no-gutters>
            <v-col class="ml-3 mb-3" cols="12" lg="5">
                <v-img aspect-ratio="1" width="20vw" src="@/assets/slider/2.jpg"></v-img>
            </v-col>
            <v-col class="text-right">
                <v-card-text>
                {{showedProgram.text2}}  
                </v-card-text>
            </v-col>
        </v-row>
    </v-card>
</v-container>
</template>

<script>
export default {
    name:"NewspaperLayout",
    props: {
        postId: {
            type: Number
        }
    },

    computed: {
        showedProgram: function(){
            this.$store.dispatch('fetchProgramById',this.postId)
            return this.$store.getters.getShowedProgram
        },
        images: function(){
            this.$store.dispatch('fetchProgramById',this.postId)
            let _showedProgram= this.$store.getters.getShowedProgram
            let loadedImages=[..._showedProgram.images]
            console.log(loadedImages)
            for (let i = 0; i < loadedImages.length; i++) {
                loadedImages[i]=require('@/assets/'+loadedImages[i])         
            }
            return loadedImages
        }
    } 
}
</script>

<style>

</style>