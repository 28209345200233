<template>
<v-container fluid>
    
    <v-card elevation="2">
        <v-img src="@/assets/slider/1.jpg">
            <v-btn class="ml-2" icon @click="close()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-img>
        <div align="center">        
            <h1 class="mt-2">{{ showedProgram.title }}</h1>
            {{ showedProgram.date }}
        </div>     
            <v-card-text class="black--text">
                {{showedProgram.text}} 
            </v-card-text>
            <v-card-text class="black--text">
                {{showedProgram.text2}}  
            </v-card-text>

    </v-card>
</v-container>
</template>

<script>
export default {
    name:"NewspaperLayout",
    props: {
        postId: {
            type: Number
        }
    },

    computed: {
        showedProgram: function(){
            this.$store.dispatch('fetchProgramById',this.postId)
            return this.$store.getters.getShowedProgram
        },
        images: function(){
            this.$store.dispatch('fetchProgramById',this.postId)
            let _showedProgram= this.$store.getters.getShowedProgram
            let loadedImages=[..._showedProgram.images]
            console.log(loadedImages)
            for (let i = 0; i < loadedImages.length; i++) {
                loadedImages[i]=require('@/assets/'+loadedImages[i])         
            }
            return loadedImages
        }
    } 
}
</script>

<style>

</style>