<template>
  <v-container fluid>
    <div>
      <v-row justify="center" align="center">
        <v-col sm=12 md="8">
          <div id="title" class="mt-10" stlye="font-size:100px">Rólunk</div>
          
          <v-divider class="mb-5"/>
          <p>
            Az Egészséges Biatorbágyért Közösségi Egyesület 14 alapító taggal a településen dolgozó orvosok és civil tagok támogatásával alakult 2001-ben. Célunk az volt, hogy az egészség megőrzése fontos és hangsúlyos szerepet kapjon a lakosság körében.
          </p>
          <p>
            Tevékenységünk alapcélkitűzéseként a népegészségügyi programmal összhangban a lakosság egészségkultúrájának erősítését és segítését, az egészség megőrzését, és összességében az életminőség javítását kívánjuk elérni.
          </p>
          <p>
            Az Egyesület költségvetésének alapját az alapító tagok és a támogatók adománya képezik.
            Egyesületünk tevékenységét segítő pályázatok figyelése és megírása útján több ízben sikerült plusz bevételi forráshoz jutnunk, ami többek között lehetőséget adott a 2019-ben megnyílt Hétszínvirág Családi Bölcsőde létesítésére.
          </p>
          <p>
            Magánszemélyek és vállalkozók is támogatják Egyesületünket, amely Közösségi egyesület, így a segítő adományok, támogatások közcélokat szolgálnak.
          </p>
          <p>
            A SzJA 1% -ára jogosultak vagyunk, így ez szintén fontos bevételi forrás.
          </p>
        
      <!--
      <v-row no-gutters justify="center" align="center">
          <ImageCard
                    title= ""
                    subtitle= "Egészséges Biatorbágyért Közösségi Egyesület"
                    text= "Az Egészséges Biatorbágyért Közösségi Egyesület 14 alapító taggal a településen dolgozó orvosok és civil tagok támogatásával alakult 2001-ben. Célunk az volt, hogy az egészség megőrzése fontos és hangsúlyos szerepet kapjon a lakosság körében."
                    imgsrc="http://hetszinviragbolcsode.hu/wp-content/uploads/2018/11/xshutterstock_717259231-555x335.jpg.pagespeed.ic.h63sEGHJ-1.webp"
            />
          <ImageCard
                    title= ""
                    subtitle= "Egészséges Biatorbágyért Közösségi Egyesület"
                    text= "Az Egészséges Biatorbágyért Közösségi Egyesület 14 alapító taggal a településen dolgozó orvosok és civil tagok támogatásával alakult 2001-ben. Célunk az volt, hogy az egészség megőrzése fontos és hangsúlyos szerepet kapjon a lakosság körében."
                    imgsrc="http://hetszinviragbolcsode.hu/wp-content/uploads/2018/11/xshutterstock_717259231-555x335.jpg.pagespeed.ic.h63sEGHJ-1.webp"
            />
            <ImageCard
                    title= ""
                    subtitle= "Egészséges Biatorbágyért Közösségi Egyesület"
                    text= "Az Egészséges Biatorbágyért Közösségi Egyesület 14 alapító taggal a településen dolgozó orvosok és civil tagok támogatásával alakult 2001-ben. Célunk az volt, hogy az egészség megőrzése fontos és hangsúlyos szerepet kapjon a lakosság körében."
                    imgsrc="http://hetszinviragbolcsode.hu/wp-content/uploads/2018/11/xshutterstock_717259231-555x335.jpg.pagespeed.ic.h63sEGHJ-1.webp"
            />
      </v-row>-->
        <v-row class="mt-10" align="center" justify="center">
          <div id="alcim" align="center" class="black--text">Tevékenységeink</div>
        </v-row>
        <v-row justify="center">
          <v-btn
            icon
            @click="$vuetify.goTo('#circle_card_container', {duration:500,easing:'easeInQuad',offset:120})"
            >
            <v-icon class="black--text">mdi-chevron-down</v-icon>
          </v-btn>
        </v-row>
        <v-row class="mb-10">
          <v-col id="circle_card_container" justify="center" align="center">
            <v-img
              class="rounded-circle"
              src="@/assets/images/vizsgalat.jpg"
              width="18rem"
            ></v-img>
            <div class="mt-5 black--text"><strong>Szűrővizsgálatok szervezése</strong></div>
          </v-col>
          <v-col justify="center" align="center">
            <v-img
              class="rounded-circle"
              src="@/assets/images/eloadas.jpg"
              width="18rem"
            ></v-img>
            <div class="mt-5 black--text"><strong>Ismeretterjesztő előadások szervezése</strong></div>
          </v-col>
          <v-col justify="center" align="center">
            <v-img
              class="rounded-circle"
              src="@/assets/images/tabor.jpg"
              width="18rem"
            ></v-img>
            <div class="mt-5 black--text"><strong>Életmód, fittnessz klub és nyári tábor szervezése</strong></div>
          </v-col>
          <v-col justify="center" align="center">
            <v-img
              class="rounded-circle"
              src="@/assets/images/children_1.jpg"
              width="18rem"
            ></v-img>
            <div class="mt-5 black--text"><strong>Családi bölcsőde létesítése</strong></div>
          </v-col>
        </v-row>
        <p class="black--text text-center">
          Az Egészséges Biatorbágyért Közösségi Egyesület munkájának szervezésében, a pályázati lehetőségek kihasználásában, az ismertség növelésében, a kitűzött célok megvalósulásáért az Egyesület elnökének tevékenységén kívül lelkes önkéntes segítők is részt vesznek, és sokat dolgoznak, melyért mindenkit köszönet illet.
        </p>
        <p class="black--text text-center">
          Bízunk benne, hogy tevékenységünkkel Biatorbágy lakosságának egészségét, egészségmegőrzését és egészségtudatos magatartását segítjük.
        </p>
      </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ImageCard from "@/components/common/ImageCard"
export default {
  name: 'About',
  components: {
    ImageCard
  }

}
</script>
<style scoped>
  @font-face {
    font-family: UniSansThin;
    src: url("~@/assets/fonts/Uni Sans Thin.otf");
  }
  @font-face {
    font-family: UniSansHeavy;
    src: url("~@/assets/fonts/Uni Sans Heavy.otf");
  }
  #title{
    font-size:3.5rem;
    font-family: UniSansThin;
  }
  #alcim {
    font-size: 2rem;
    font-family: UniSansHeavy;
  }
  #roundImg {
    border-radius: 50%;
    min-height: 500px;
  min-width: 500px;
  }
</style>

