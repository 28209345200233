import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '@/views/About'
import Nursery from '@/views/Nursery'
import Programs from '@/views/Programs'
import MobilePost from '@/views/MobilePost'
import AllPrograms from '@/views/AllPrograms'
import Reports from '@/views/Reports'
import Competition from "@/views/Competition.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title:'EBKE'
    }
  },
  {
    path: '/rolunk',
    name: 'Rólunk',
    component: About,
    meta:{
      title:'EBKE-Rólunk'
    }
  },
  {
    path: '/bolcsode',
    name: 'Bölcsőde',
    component: Nursery
  },
  {
    path: '/programok',
    name: 'Programok',
    component: Programs
  },
  {
    path: '/program',
    name: 'MobilePost',
    component: MobilePost
  },
  {
    path: '/korabbi-programok',
    name: 'AllPrograms',
    component: AllPrograms
  },
  {
    path: '/beszamolok',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/palyazat',
    name: 'Competition',
    component: Competition
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
