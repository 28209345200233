<template>
  <v-card
    max-width="600"
    elevation=10
  >
    <v-card-title class="text-h6 blue lighten-2 white--text">
        <div id="title">{{title}}</div>
        <v-spacer/>
        {{date}}
    </v-card-title>
    <v-card-text class="mt-2">
      <div class="text--primary my-2">
        {{text1}}
      </div>
      <v-divider/>
      <div class="text--primary my-2">
        {{text2}}
      </div>
    </v-card-text>
    <v-card-actions v-if="$vuetify.breakpoint.mdAndUp">
            <v-btn
                v-if="$vuetify.breakpoint.mdAndUp && button"    
                elevation=5
                :color="button.color"
                class="pa-5 ma-2"
                @click="$emit('clicked',id), $vuetify.goTo(250, {duration:500, easing:'easeInQuad'})"
          >
            {{button.text}}
          </v-btn> 
          <v-btn
                v-else-if="button"    
                elevation=5
                :color="button.color"
                class="pa-5 ma-2"
                :href="href"
          >
            {{button.text}}
          </v-btn>      
        </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: "Card",
    props: {
        id: {
          type: Number
        },
        title: {
            type: String,
            default: "Cím"
        },
        text1: {
            type: String,
            default: "Szöveg"
        },
        text2: {
            type: String,
            default: ""
        },
        date: {
            type: String,
            default: ""
        },
        button: {
            type: Object,
            default: null
        },
        href: {
            type: String,
            default: ""
        }
    },
    methods: {
      emitEvent() {
        this.$emit('clicked',this.$props.id)
      }

    }
}
</script>

<style>
#title{
  margin: 1px 0 1px 0;
}
</style>