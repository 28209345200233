<template>
<v-container>
  <v-row>
    <v-timeline
      dense
    >
      <v-timeline-item
          v-for="program in getActualPrograms"
          :key="program.id"
        >
            <Card
              class="mr-10"
              :id="program.id"
              :title="program.title"
              :text1="program.text"
              :date="program.date"
              :button="{
                  text:'Tovább',
                  color:'primary'                  
                }"
              @clicked="id => $emit('clicked',id)"
              :href="'@/assets/documents/beszamolo_2017.pdf'"
          />
        </v-timeline-item>     
      </v-timeline>  
  </v-row>
  <v-btn
      class="mt-5 ml-10"
      color="primary"
      href="/korabbi-programok"
    >
      Korábbi programok
    </v-btn>
  </v-container>
</template>

<script>
    import Card from "@/components/common/Card"
    import { mapGetters, mapActions } from "vuex"
    export default {
        name: "TimeLine",
        components: {
          Card
        },
        data(){ 
          return {
            programs: [],
            emitId: ""            
          }
        },
              
        methods: {
           ...mapActions(["fetchActualPrograms"]),  
          emitEvent(id){
             this.$emit('clicked',id)
          }         
        },
        
        created() {
          this.fetchActualPrograms();
        },

        computed: mapGetters(["getActualPrograms"])

        
    }
</script>
