<template>
  <v-footer
    class="pa-0 mt-10"
    dark
    padless
  >
    <v-card
      flat
      tile
      class="flex blue white--text text-center"
    >
      <v-card-text class="white--text">
        2001 - {{ new Date().getFullYear() }} — <strong>Egészséges Biatorbágyért Közösségi Egyesület</strong>
        <v-btn
          v-for="icon in icons"
          :key="icon.icon"
          class="mx-1 white--text"
            :href="icon.path"
            target="_blank"
          icon
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
    data: () => ({
      icons: [
        {
            icon: 'mdi-facebook',
            path: 'https://www.facebook.com/Egészséges-Biatorbágyért-Közösségi-Egyesület-292993520901291'
        },
      ],
    }),
  }
</script>

<style>

</style>