<template>
  <v-container fluid>
    <div>
      <v-row justify="center" align="center">
        <v-col sm=12 md="8">
          <div id="title" class="mt-10" stlye="font-size:100px">Pályázati információk</div>
          
          <v-divider class="mb-5"/>
          <p>
            <strong>Kezdeményezett neve: </strong>Egészséges Biatorbágyért Közösségi Egyesület
          </p>
          <p>
            <strong>Projekt címe: </strong>Gyermekellátó kapacitások fejlesztése az Egészséges Biatorbágyért Közösségi Egyesületnél
          </p>
          <p>
            <strong>Szerződött támogatás mértéke: </strong>49 443 685 Ft
          </p>
          <p>
            <strong>Támogatás mértéke: </strong>100%
          </p>
          <p>
            <strong>Projekt tartalmának bemutatása: </strong><a href="./documents/osszefoglalas.pdf" target="_blank">Melléklet link</a>
          </p>
          <p>
            <strong>A projekt tervezett befejezési dátuma: </strong>2019.03.31.
          </p>
          <p>
            <strong>A projekt azonosító száma: </strong>VEKOP-6.1.1-15-PT1-2016-00087
          </p>
      </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ImageCard from "@/components/common/ImageCard"
export default {
  name: 'About',
  components: {
    ImageCard
  }

}
</script>
<style scoped>
  @font-face {
    font-family: UniSansThin;
    src: url("~@/assets/fonts/Uni Sans Thin.otf");
  }
  @font-face {
    font-family: UniSansHeavy;
    src: url("~@/assets/fonts/Uni Sans Heavy.otf");
  }
  #title{
    font-size:3.5rem;
    font-family: UniSansThin;
  }
  #alcim {
    font-size: 2rem;
    font-family: UniSansHeavy;
  }
  #roundImg {
    border-radius: 50%;
    min-height: 500px;
  min-width: 500px;
  }
</style>

