<template>
    <nav>
      <v-toolbar app dark  color="blue" v-if="$vuetify.breakpoint.mdAndUp">
         <v-spacer></v-spacer>
         <a @click="goToPath('/')" class="white--text" style="text-decoration: none"><h3 class="mr-2">EBKE</h3></a>
        <a @click="goToPath('/')">
        <v-toolbar-title class="mr-5">
               <v-img
                width="5vh"
                src="@/assets/logo.png"    
                      
                ></v-img>               
           </v-toolbar-title>
        </a>
        <v-toolbar-items>
         <v-btn
           v-for="item in nav"
           :key="item.path"
           :to="item.path"
           :title="item.title"          
           plain
            color="grey lighten-5"
            class="font-weight-regular text-capitalize" 
         >{{ item.title }}</v-btn>
        </v-toolbar-items>
        <v-spacer></v-spacer>
      </v-toolbar>

        <v-toolbar
          color="blue"
          dark
          v-else
        >
          <h3 class="mr-2" style="color:white">EBKE</h3>
          <v-toolbar-title class="">
               <v-img
                width="2.5rem"
                src="@/assets/logo.png"
                ></v-img>
           </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="drawer = !drawer" ></v-app-bar-nav-icon>
        </v-toolbar>

        <v-navigation-drawer 
          v-model="drawer"
          right
          fixed
          temporary
        >
          <v-app-bar
          color="blue"
          dark
          >
            <h3 class="pa-2">Menü</h3>
            <v-spacer></v-spacer>
            <v-icon @click="drawer = !drawer">mdi-close</v-icon>
          </v-app-bar>
          
       
          <v-list
            v-if="drawer"
            nav
            dense
          >
            <v-list-item-group
              v-model="group"
              active-class="deep-blue--text text--accent-4"
            >
              <v-list-item v-for="item in nav"
              :key="item.path"
              :to="item.path">
                <v-list-item-title>{{item.title}}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>

    </nav>
</template>

<script>
export default {
    name: "Navbar",
    data(){
        return {
            drawer: false,
            group: null,
            nav: [
                { title: 'Főoldal', path: '/' },
                { title: 'Rólunk', path: '/rolunk' },
                { title: 'Pályázat', path: '/palyazat' },
                { title: 'Beszámolók', path: '/beszamolok' },
                { title: 'Bölcsőde', path: '/bolcsode' },
            ]
        }
    },
    watch: {
      group () {
        this.drawer = false
      },
    },
    methods: {
      onClickOutside () {
        this.drawer = false
      },
      goToPath(path) {
        this.$router.push(path)
      }
    }
}
</script>

<style>

</style>