<template>
    <v-container
        fluid
        fill-height
    >
        <v-row 
            justify="center"
            align="center"  
        >
            <ImageCard
                title= ""
                subtitle= "Hétszínvirág családi bölcsőde"
                text= "A Hétszínvirág Bölcsőde 1 éves kortól 3 éves korig nyugodt, családias és inspiráló környezetben várja a gyermekeket. Célunk, hogy a gyermekeket megfelelő szakértelemmel, ugyanakkor mindennapi móka és játék útján készítsük fel az óvodai életre."
                :button= "{
                    text:'Ugrás a weboldalra!',
                    color:'blue lighten-2'
                }"
                href="https://www.hétszínvirágcsaládibölcsöde.hu"
                _width="4"
            />
            
        </v-row>
    </v-container>
</template>

<script>
import ImageCard from '@/components/common/ImageCard'
export default {
    components: {
        ImageCard    
    }
}
</script>

<style>

</style>