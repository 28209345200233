import axios from 'axios';

const serverAddress = 'http://localhost:3000'


const state = {   
    actualPrograms: [
      {
        "id": 1,
        "typeId": 1,
        "title": "Beszámoló 2017",
        "date": "",
        "text" : "Egyesületünk 2017.évi programjának tervezésekor folytatni kívánjuk az előző években megvalósult szakmai programokat. Támogatjuk a szakma részéről és a lakosság köréből jelentkező új kezdeményezéseket, javaslatokat és segítséget nyújtunk azok megszervezésében és lebonyolításában. Munkánkkal több szinten kapcsolódunk Biatorbágy város Önkormányzata „Egészséges Biatorbágyért” Népegészségügyi Programjához. Rendezvényeink során igyekszünk megszólítani városunk lakosságának valamennyi korosztályát és rétegét. Azt tapasztalatjuk, hogy a lakosság érdeklődik, és várja is az EBKE által szervezett programokat, és a résztvevők létszáma rendezvényeinken növekvő tendenciát mutat. Munkánk során a programok szervezésében együttműködünk a Magyar Vöröskereszt Biatorbágy Alapszervezete, a Juhász Ferenc Művelődési Központ, az Egészségügyi Központ Biatorbágy, a Biatorbágyi Családsegítő és Gyermekjóléti Szolgálat munkatársaival és önkéntes aktivistáival. A későbbiekben igyekszünk szélesíteni együttműködő kapcsolatainkat és megpróbálunk bevonni a programok szervezéséhez a Biatorbágyon működő több civil szervezetet.",
        "images": ['slider/1.jpg','slider/2.jpg','slider/3.jpg']    
      },
      {
        "id": 2,
        "typeId": 2,
        "title": "A 2021-es nyári tábor regisztrációja elindult",
        "date": "2021.07.16.",
        "text" : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "text2" : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "image": "images/children.jpg"      
      },
      {
        "id": 3,
        "typeId": 3,
        "title": "A hőség fokozódik térségünkben",
        "date": "2021.07.16.",
        "text" : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "text2" : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "images": ["images/children.jpg","slider/2.jpg","slider/3.jpg"]      
      },
      {
        "id": 4,
        "typeId": 3,
        "title": "Új baba-mama képzés indul a városban",
        "date": "2021.07.16.",
        "text" : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "text2" : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "images": ["images/children.jpg","slider/2.jpg","slider/3.jpg"]      
      },
      {
        "id": 5,
        "typeId": 3,
        "title": "Közeleg a tél, előkerülnek a téli kábotok",
        "date": "2021.07.16.",
        "text" : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "text2" : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        "image": "images/children.jpg"     
      }
    ],
    showedProgram: {}
  };
  
  const getters = {
    getActualPrograms: state => state.actualPrograms,
    getShowedProgram: state => state.showedProgram,    
  };
  
  const actions = {
    async fetchActualPrograms({ commit }) {
      /* Depricated until the presentation
      const response = await axios.get(`${serverAddress}/posts`);
      commit('setActualPrograms', response.data);
      */
    },
    
    async fetchProgramById({ commit, state},id){
      /*  Depricated until the presentation     
        const response = await axios.get(`${serverAddress}/posts/${id}`);
        commit('setProgram', response.data);
      */
      commit('setProgram', state.actualPrograms.find(program => program.id == id));
    }
  };
  
  const mutations = {
    setActualPrograms: (state, programs) => state.actualPrograms = programs,   
    setProgram : (state, program) =>  state.showedProgram = program,
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };