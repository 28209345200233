<template>
    <v-container fluid>      
            <v-row justify="center">
                <v-col md=8 sm=12 justify="center">
                    <div v-if="$vuetify.breakpoint.mdAndUp" id="title" class="mt-10 medium-font">Beszámolók</div>
                    <div v-else id="title" align="center" class="mt-10 mobile-font">Beszámolók</div>

                    <v-divider class="mb-5"/>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <ImageCard
                    title="EBKE beszámoló 2017"
                    subtitle="Az Egészséges Biatorbágyért Közösségi Egyesület tervezett feladatai és programja 2017-ben!"
                    text="Egyesületünk 2017.évi programjának tervezésekor folytatni kívánjuk az előző években megvalósult szakmai programokat. Támogatjuk a szakma részéről és a lakosság köréből jelentkező új kezdeményezéseket, javaslatokat és segítséget nyújtunk azok megszervezésében és lebonyolításában. Munkánkkal több szinten kapcsolódunk Biatorbágy város Önkormányzata „Egészséges Biatorbágyért” Népegészségügyi Programjához. Rendezvényeink során igyekszünk megszólítani városunk lakosságának valamennyi korosztályát és rétegét. Azt tapasztalatjuk, hogy a lakosság érdeklődik, és várja is az EBKE által szervezett programokat, és a résztvevők létszáma rendezvényeinken növekvő tendenciát mutat. Munkánk során a programok szervezésében együttműködünk a Magyar Vöröskereszt Biatorbágy Alapszervezete, a Juhász Ferenc Művelődési Központ, az Egészségügyi Központ Biatorbágy, a Biatorbágyi Családsegítő és Gyermekjóléti Szolgálat munkatársaival és önkéntes aktivistáival. A későbbiekben igyekszünk szélesíteni együttműködő kapcsolatainkat és megpróbálunk bevonni a programok szervezéséhez a Biatorbágyon működő több civil szervezetet."
                    imgsrc=""
                    _width=8
                    href="./documents/beszamolo_2017.pdf"
                    :button="{
                        text:'Tovább',
                        color:'blue lighten-2',
                        target: '_blank'
                    }"
                />
            </v-row>
            <v-row align="center" justify="center">
                <ImageCard
                    title="EBKE beszámoló 2016"
                    subtitle="Az Egészséges Biatorbágyért Közösségi Egyesület tervezett feladatai és programja 2016-ban!"
                    text="Egyesületünk 2016. évi programja az előző évek programjaihoz hasonló szakmai programot valósít meg, ezen felül támogatja a szakma részéről és a lakosság köréből jelentkező új kezdeményezéseket, javaslatokat és segítséget nyújt azok megszervezésében és lebonyolításában. (pl.: Diabetes klub, Nordic Walking sport klub 10-90 éves korig, Erőszakmentes együttműködő kommunikáció csoport foglalkozás.) A tapasztalat azt mutatja, hogy a lakosság érdeklődik, várja az EBKE által szervezett programokat, és a résztvevők létszáma rendezvényeinken folyamatosan növekszik. Ezt a tényt kötelező erejűnek érzem a munka folytatására és úgy érzem nem lehet abbahagyni a lelkes önkéntes szervező tevékenységet sem."                imgsrc=""
                    _width=8
                    href="./documents/beszamolo_2016.pdf"
                    :button="{
                        text:'Tovább',
                        color:'blue lighten-2'
                    }"
                />
            </v-row>
            <v-row align="center" justify="center">
                <ImageCard
                    title="EBKE beszámoló 2015"
                    subtitle="Beszámoló a városi egészségnapról!"
                    text="2015. szeptember 19-én Biatorbágy Város Önkormányzatának megbízásával az Egészséges Biatorbágyért Közösségi Egyesület harmadik alkalommal szervezte meg a biatorbágyi Városi Egészségnapot. Rendezvényünket idén egy kellemes őszi szombaton tartottuk. Az előző évek szervezési tapasztalatait felhasználva, de az erre szánt pénzügyi keret szorítása mellett állítottuk össze a programot. A szervezésben a Faluház dolgozói nyújtotta technikai segítség, és Szolnoki Brigitta szervezésszamai munkája, valamint önkéntes munkával Dancsa Anna, Nánási Szikra Ágnes, Zoller Orsolya civil segítők, valamint az Egészségház részéről Bancsó Zsuzsanna szakasszisztens és a Vöröskereszt helyi szervezete Szanyi József vezetésével járultak hozzá a rendezvény sikeres lebonyolításához."
                    _width=8
                    href="./documents/beszamolo_2015.pdf"
                    :button="{
                        text:'Tovább',
                        color:'blue lighten-2'
                    }"
                />
            </v-row>
            <v-row align="center" justify="center">
                <ImageCard
                    title="EBKE beszámoló 2014"
                    subtitle="Beszámoló a 2014. Városi eséyegyenlőségi egészség és sportnapról!"
                    text="2014. szeptember 20.-n szombaton, az Egézséges Biatorbágyért Közösségi Egyesület (EBKE) Biatorágy Város Önkormányzata megbízásábó szervezte a „Városi esélyegyenlőségi egészség és sportnapot”. A „Városi Egészségnap”-nak évekre visszanyúló hagyományai vannak városunkban. Ezt a hagyományt kívántuk folytatni, azzal a céllal, hogy felhívjuk a figyelmet az egészséges életód és az egészségmegörzés fontosságára és lehetőségeire. Egyesületünk alapcélkitűzése az egészségkultúra erősítése és segítése, az egészség megörzése, összességében az életminőség javítása Biatorbágy lakóinak körében. Tevékenységünkkel a Biatorbágy város Önkormányzata átal korábban kidolgozott az „Egészséges Biatorbágyért Népegészségügyi Program” céljait kívánjuk segíteni, és igyekszünk ezt megismertetni a biatorbágyi lakosokkal."
                    _width=8
                    href="./documents/beszamolo_2014.pdf"
                    :button="{
                        text:'Tovább',
                        color:'blue lighten-2'
                    }"
                />
            </v-row>
            <v-row align="center" justify="center">
                <ImageCard
                    title="EBKE beszámoló 2013"
                    subtitle="Beszámoló a városi egészségnapról!"
                    text="A városi Egészségnapnak hosszú évekre visszanyúló hagyományai vannak Biatorbágyon. Célja, hogy felhívja a figyelmet az egészségmegőrzés fontosságára és lehetőségeire, és tudatosítsa, hogy szűrővizsgálatok segítségével a betegségek tünetmentes szakban felfedhetőek, megelőzhetőek. 2013. szeptember 14-én Biatorbágy Város Önkormányzatának megbízásával az Egészséges Biatorbágyért Közösségi Egyesület szervezte a városi Egészségnapot, amely nagy megtiszteltetés volt Egyesületünk számára, alkalmat adva a bemutatkozásra és a szakmai munka kiteljesítésére."
                    _width=8
                    href="./documents/beszamolo_2013.pdf"
                    :button="{
                        text:'Tovább',
                        color:'blue lighten-2'
                    }"
                />
            </v-row>
        </v-container>
        
    
</template>

<script>
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
import ImageCard from '@/components/common/ImageCard'
export default {
    components: {
        ImageCard
    },
    data(){
      return {
        showedPostId: 1,
        show:false
      }
    },
    methods: {
        setPostIdForContent(id){
          this.showedPostId = id
          if(this.show) {
             this.show = false
          }
          setTimeout(()=>{
            this.show = true
          },200)
        }
    },
    computed: {
      showedProgram: function(){
        return this.$store.getters.getActualPrograms.find(program => program.id == this.showedPostId)
      }
    }
}
</script>
<style scoped>
  @font-face {
    font-family: UniSans;
    src: url("~@/assets/fonts/Uni Sans Thin.otf");
  }
  #title{
    font-family: UniSans;
    font-size:3.5rem;
  }
  .medium-font {
    font-size: 2rem;
  }

  .mobile-font {
    font-size: 1rem;
  }

  #card{
    margin: 12px 0 0 0;
  }
</style>